<template>
  <v-sheet
    v-bind="$attrs"
    class="pa-3 cursor-drag fullwidth d-flex flex-column align-center justify-start"
  >
    <label class="align-self-start mb-1">
      <ContentEditable
        :style="`color:${label_color}`"
        class="fw-500 fs-15"
        :value="structures.label"
        @input="updateProp('label', $event)"
      ></ContentEditable>
    </label>
    <v-row dense align="center" justify="space-between" class="fullwidth">
      <v-col cols="11" class="d-flex fullwidth align-center justify-center">
        <v-text-field
          :value="structures.value"
          clearable
          @click:clear="structures.value = null"
          hide-details="auto"
          @click="modal = true"
          append-icon="mdi-clock"
          readonly
          outlined
          dense
        ></v-text-field>
      </v-col>
      <v-col cols="1" class="d-flex align-center justify-center">
        <v-menu
          :close-on-content-click="false"
          v-model="showMenu"
          absolute
          offset-x
        >
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-bind="attrs" v-on="on" color="primary"> mdi-cog </v-icon>
          </template>
          <v-card elevation="3" width="400">
            <v-card-title
              class="d-flex primary--text fw-700 fullwidth justify-center align-center"
            >
              Properties
            </v-card-title>
            <v-card-text>
              <div class="d-flex fullwidth mb-1 flex-column">
                <label class="subtitle-2 primary--text">Replace with</label>
                <FormType
                  :value="answer_type"
                  @change="$emit('replace', $event)"
                ></FormType>
              </div>
              <div class="d-flex fullwidth mb-2 flex-column">
                <label class="subtitle-2 primary--text">Min Time</label>
                <v-menu
                  ref="menu1"
                  v-model="menu1"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  :return-value.sync="structures_min_time"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      :value="structures.min_time"
                      dense
                      clearable
                      @click:clear="structures_min_time = null"
                      hide-details="auto"
                      outlined
                      append-icon="mdi-clock"
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-time-picker
                    v-if="$refs.menu1"
                    v-model="time1"
                    ampm-in-title
                    :max="structures.max_time"
                    @click:minute="$refs.menu1.save(time1)"
                  ></v-time-picker>
                </v-menu>
              </div>
              <div class="d-flex fullwidth mb-2 flex-column">
                <label class="subtitle-2 primary--text">Max Time</label>
                <v-menu
                  ref="menu2"
                  v-model="menu2"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  :return-value.sync="structures_max_time"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      :value="structures.max_time"
                      dense
                      clearable
                      @click:clear="structures_max_time = null"
                      hide-details="auto"
                      outlined
                      append-icon="mdi-clock"
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-time-picker
                    v-if="$refs.menu2"
                    v-model="time2"
                    ampm-in-title
                    :min="structures.min_time"
                    @click:minute="$refs.menu2.save(time2)"
                  ></v-time-picker>
                </v-menu>
              </div>
            </v-card-text>
            <v-card-actions class="d-flex align-center justify-space-between">
              <div class="d-flex align-center justify-start">
                <v-checkbox
                  dense
                  :value="structures.required"
                  @change="updateProp('required', $event)"
                  class="align-self-center mt-0"
                  hide-details="auto"
                  color="primary"
                  :true-value="true"
                  :false-value="false"
                  label="Required"
                >
                </v-checkbox>
              </div>
              <div class="d-flex align-center justify-end">
                <v-btn
                  @click="emitDelete"
                  depressed
                  text
                  class="text-none ml-1"
                  color="primary"
                >
                  <v-icon left>mdi-delete</v-icon> Delete
                </v-btn>
                <v-btn
                  @click="emitDuplicate"
                  depressed
                  text
                  class="text-none ml-1"
                  color="primary"
                >
                  <v-icon left>mdi-content-copy</v-icon> Duplicate
                </v-btn>
              </div>
            </v-card-actions>
          </v-card>
        </v-menu>
      </v-col>
    </v-row>
    <v-dialog
      ref="dialog"
      v-model="modal"
      :return-value.sync="structures_value"
      persistent
      width="300px"
    >
      <v-time-picker
        v-model="time"
        :min="structures.min_time"
        :max="structures.max_time"
        full-width
        ampm-in-title
      >
        <v-spacer></v-spacer>
        <v-btn text color="primary" @click="modal = false"> Cancel </v-btn>
        <v-btn text color="primary" @click="$refs.dialog.save(time)">
          OK
        </v-btn>
      </v-time-picker>
    </v-dialog>
  </v-sheet>
</template>

<script>
import _ from 'lodash'
import ContentEditable from '@/common/Forms/ContentEditable.vue'
import FormType from '@/common/Forms/FormType.vue'
import { mapGetters, mapMutations } from 'vuex'
export default {
  components: { ContentEditable, FormType },
  inheritAttrs: false,
  name: 'FormTime',
  props: {
    sectionIndex: { type: Number, default: 0 },
    fieldIndex: { type: Number, default: 0 }
  },
  data: () => ({
    showMenu: false,
    modal: false,
    menu1: false,
    menu2: false,
    time: null,
    time1: null,
    time2: null,
    defaults: {
      id: null,
      type: 'time',
      tag: 'FormTime',
      label: 'Time',
      icon: 'mdi-clock',
      show_icon: false,
      placeholder: 'Select time',
      value: null,
      dummy_value: null,
      min_time: null,
      max_time: null,
      time_format: 'HH:mm A',
      required: false,
      hover: false
    },
    answer_type: 'time'
  }),
  computed: {
    ...mapGetters('forms', ['label_color']),
    structures() {
      return this.$store.getters['forms/get_section_items_by_indexes'](
        this.sectionIndex,
        this.fieldIndex
      )
    },
    structures_value: {
      get() {
        return this.structures.value || null
      },
      set(val) {
        this.updateProp('value', val)
      }
    },
    structures_min_time: {
      get() {
        return this.structures.min_time || null
      },
      set(val) {
        this.updateProp('min_time', val)
      }
    },
    structures_max_time: {
      get() {
        return this.structures.max_time || null
      },
      set(val) {
        this.updateProp('max_time', val)
      }
    }
  },
  methods: {
    ...mapMutations('forms', ['update_section_item_prop']),
    updateProp(prop, val) {
      this.update_section_item_prop({
        sx: this.sectionIndex,
        ix: this.fieldIndex,
        prp: prop,
        val: val
      })
    },
    emitDelete() {
      this.$emit('delete', this.structures)
    },
    emitDuplicate() {
      this.$emit('duplicate', this.structures)
    }
  }
}
</script>

<style lang="scss" scoped></style>
