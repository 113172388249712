export const builder_mixin = {
    data() {
        return {
            date: {
                id: null,
                type: 'date',
                tag: 'FormDate',
                label: 'Date',
                icon: 'mdi-calendar',
                show_icon: false,
                placeholder: 'Select date',
                value: null,
                dummy_value: null,
                min_date: null,
                max_date: null,
                date_format: 'Y-M-D',
                required: false,
                hover: false
            },
            time: {
                id: null,
                type: 'time',
                tag: 'FormTime',
                label: 'Time',
                icon: 'mdi-clock',
                show_icon: false,
                placeholder: 'Select time',
                value: null,
                dummy_value: null,
                min_time: null,
                max_time: null,
                time_format: 'HH:mm A',
                required: false,
                hover: false
            },
            short_text: {
                id: null,
                type: 'text',
                tag: 'FormShortText',
                label: 'Untitled Question',
                icon: 'mdi-alpha-t-box-outline',
                show_icon: false,
                placeholder: 'Your answer',
                value: null,
                dummy_value: null,
                required: true,
                hover: false,
                min: null,
                max: null
            },
            file_upload: {
                id: null,
                type: 'file_upload',
                tag: 'FormFileUpload',
                tag_type: 'file',
                label: 'Upload file',
                icon: 'mdi-paperclip',
                show_icon: false,
                placeholder: 'Upload file',
                value: null,
                accept: ['*'],
                dummy_value: null,
                props: [],
                required: false,
                hover: false,
                error: false,
                multiple: false,
                max: 1
            },
            long_text: {
                id: null,
                type: 'textarea',
                tag: 'FormParagraph',
                label: 'Untitled Question',
                icon: 'mdi-format-line-weight',
                show_icon: false,
                placeholder: 'Your answer',
                value: null,
                dummy_value: null,
                rows: 3,
                required: false,
                hover: false
            },
            dropdown: {
                id: null,
                type: 'dropdown',
                tag: 'FormDropdown',
                label: 'Select',
                icon: 'mdi-arrow-down-drop-circle-outline',
                show_icon: false,
                placeholder: 'Select your answer',
                value: null,
                dummy_value: null,
                required: false,
                multiple: false,
                hover: false,
                items: ['Select 1', 'Select 2']
            },
            multiple_choice: {
                id: null,
                type: 'checkboxes',
                tag: 'FormMultipleChoice',
                label: 'Select',
                icon: 'mdi-checkbox-multiple-marked-outline',
                show_icon: false,
                multiple: true,
                value: [],
                dummy_value: [],
                required: false,
                hover: false,
                direction: 'column', //or row
                items: ['Option 1', 'Option 2']
            },
            checkbox: {
                id: null,
                type: 'checkbox',
                tag: 'FormCheckbox',
                label: 'Check',
                icon: 'mdi-checkbox-marked-outline',
                show_icon: false,
                multiple: true,
                true_value: true,
                false_value: false,
                value: false,
                dummy_value: true,
                hover: false
            },
            image: {
                id: null,
                type: 'image',
                tag: 'DisplayImage',
                src: require('@/assets/temp/image-placeholder.png'),
                attachment_uuid: null,
                itemheight: 300,
                itemwidth: 300,
                maxWidth: '100%',
                maxHeight: '100%',
                alt: 'No image source found',
                hover: false,
                icon: 'mdi-image',
                label: null,
                has_label: false,
                align: 'center'
            },
            video: {
                id: null,
                type: 'video',
                tag: 'DisplayVideo',
                src: '',
                itemheight: 300,
                itemwidth: 500,
                maxWidth: '100%',
                maxHeight: '100%',
                hover: false,
                icon: 'mdi-youtube',
                label: null,
                has_label: false,
                align: 'center'
            },
            text: {
                id: null,
                type: 'paragraph',
                tag: 'DisplayText',
                text: 'Some text',
                font_size: 16,
                font_weight: 300,
                font_color: '#000000',
                hover: false,
                align: 'center',
                icon: 'mdi-format-paragraph'
            },
            section: {
                id: null,
                type: 'section',
                tag: 'FormSection',
                label: 'Section',
                icon: 'mdi-alpha-s-box',
                show_icon: false,
                hover: false,
                value: [],
                dummy_value: [],
                conditions: null
            }
        }
    },
    computed: {
        forms() {
            return [
                this.short_text,
                this.long_text,
                this.file_upload,
                this.dropdown,
                this.multiple_choice,
                this.checkbox,
                this.date,
                this.time
            ]
        },
        displays() {
            return [this.image, this.video, this.text]
        }
    }
}