<template>
  <v-select
    v-bind="$attrs"
    v-model="type"
    dense
    item-value="value"
    item-text="text"
    outlined
    hide-details="auto"
    @change="emitChange"
    :items="type_of_answers_filtered"
  >
    <template v-slot:item="{ item }">
      <span>
        <v-list-item :disabled="item.disabled">
          <v-list-item-icon>
            <v-icon :color="item.disabled ? 'grey' : 'primary'">
              {{ item.icon }}
            </v-icon>
          </v-list-item-icon>
          <v-list-item-title
            :class="[item.disabled ? 'grey--text' : 'primary--text']"
            class="fw-700"
          >
            {{ item.text }}
          </v-list-item-title>
        </v-list-item>
      </span>
    </template>
  </v-select>
</template>

<script>
export default {
  inheritAttrs: false,
  name: 'FormType',
  props: {
    value: { type: String, default: 'short_text' }
  },
  data() {
    return {
      type: 'short_text',
      type_of_answers: [
        {
          icon: 'mdi-form-textbox',
          text: 'Short Answer',
          value: 'short_text',
          disabled: false
        },
        {
          icon: 'mdi-menu',
          text: 'Paragraph',
          value: 'long_text',
          disabled: false
        },
        {
          icon: 'mdi-cloud-upload-outline',
          text: 'File Upload',
          value: 'file_upload',
          disabled: false
        },
        {
          icon: 'mdi-form-dropdown',
          text: 'Dropdown',
          value: 'dropdown',
          disabled: false
        },
        {
          icon: 'mdi-checkbox-marked-outline',
          text: 'Checkbox',
          value: 'checkbox',
          disabled: false
        },
        {
          icon: 'mdi-format-list-checks',
          text: 'Multiple Choice',
          value: 'multiple_choice',
          disabled: false
        },
        { icon: 'mdi-calendar', text: 'Date', value: 'date', disabled: false },
        { icon: 'mdi-clock', text: 'Time', value: 'time', disabled: false }
      ]
    }
  },
  computed: {
    type_of_answers_filtered() {
      return this.type_of_answers.map((i) => {
        i.disabled = i.value === this.value
        return i
      })
    }
  },
  watch: {
    value: {
      handler: function (val) {
        this.type = val
      },
      immediate: true
    },
    type: {
      handler: function (val) {
        this.$emit('input', val)
      },
      immediate: true
    }
  },
  methods: {
    emitChange() {
      this.$emit('change', this.type)
    }
  }
}
</script>

<style></style>
