<template>
  <v-sheet
    v-bind="$attrs"
    class="pa-3 cursor-drag fullwidth d-flex flex-column align-center justify-start"
  >
    <v-hover v-slot:default="{ hover }">
      <v-row
        dense
        align="center"
        justify="space-between"
        class="py-2 fullwidth"
      >
        <v-col
          cols="11"
          :class="{ 'border-1 border-dotted rounded-5': hover }"
          class="d-flex fullwidth align-center justify-start"
        >
          <v-checkbox
            hide-details="auto"
            class="my-0"
            :key="structures.label"
            :true-value="structures.true_value"
            :false-value="structures.false_value"
            :placeholder="structures.placeholder"
          >
          </v-checkbox>
          <ContentEditable
            :style="`color:${label_color}`"
            class="fw-500 fs-15"
            :value="structures.label"
            @input="updateProp('label', $event)"
          ></ContentEditable>
        </v-col>
        <v-col cols="1" class="d-flex align-center justify-center">
          <v-menu
            :close-on-content-click="false"
            v-model="showMenu"
            absolute
            offset-x
          >
            <template v-slot:activator="{ on, attrs }">
              <v-icon v-bind="attrs" v-on="on" color="primary">
                mdi-cog
              </v-icon>
            </template>
            <v-card elevation="3" width="400">
              <v-card-title
                class="d-flex primary--text fw-700 fullwidth justify-center align-center"
              >
                Properties
              </v-card-title>
              <v-card-text>
                <div class="d-flex fullwidth mb-2 flex-column">
                  <label class="subtitle-2 primary--text">Replace with</label>
                  <FormType
                    :value="answer_type"
                    @change="$emit('replace', $event)"
                  ></FormType>
                </div>
                <div class="d-flex fullwidth mb-2 flex-column">
                  <label class="subtitle-2 primary--text">Checked value </label>
                  <v-text-field
                    dense
                    placeholder="True/Yes/Agree, etc"
                    outlined
                    hide-details="auto"
                    :value="structures.true_value"
                    @input="updateProp('true_value', $event)"
                  ></v-text-field>
                </div>
                <div class="d-flex fullwidth mb-2 flex-column">
                  <label class="subtitle-2 primary--text">
                    Unchecked value
                  </label>
                  <v-text-field
                    dense
                    placeholder="False/No/Disagree, etc"
                    outlined
                    hide-details="auto"
                    :value="structures.false_value"
                    @input="updateProp('false_value', $event)"
                  ></v-text-field>
                </div>
              </v-card-text>
              <v-card-actions class="d-flex align-center justify-end">
                <v-btn
                  @click="emitDelete"
                  depressed
                  text
                  class="text-none ml-1"
                  color="primary"
                >
                  <v-icon left>mdi-delete</v-icon> Delete
                </v-btn>
                <v-btn
                  @click="emitDuplicate"
                  depressed
                  text
                  class="text-none ml-1"
                  color="primary"
                >
                  <v-icon left>mdi-content-copy</v-icon> Duplicate
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-menu>
        </v-col>
      </v-row>
    </v-hover>
  </v-sheet>
</template>

<script>
import _ from 'lodash'
import ContentEditable from '@/common/Forms/ContentEditable.vue'
import FormType from '@/common/Forms/FormType.vue'
import { mapGetters, mapMutations } from 'vuex'
export default {
  components: { ContentEditable, FormType },
  inheritAttrs: false,
  name: 'FormCheckbox',
  props: {
    sectionIndex: { type: Number, default: 0 },
    fieldIndex: { type: Number, default: 0 }
  },
  computed: {
    ...mapGetters('forms', ['label_color']),
    structures() {
      return this.$store.getters['forms/get_section_items_by_indexes'](
        this.sectionIndex,
        this.fieldIndex
      )
    }
  },
  data: () => ({
    showMenu: false,
    defaults: {
      id: null,
      type: 'checkbox',
      tag: 'FormCheckbox',
      label: 'Check',
      icon: 'mdi-checkbox-marked-outline',
      show_icon: false,
      multiple: true,
      true_value: true,
      false_value: false,
      value: false,
      dummy_value: true,
      hover: false
    },
    answer_type: 'checkbox'
  }),
  methods: {
    ...mapMutations('forms', ['update_section_item_prop']),
    updateProp(prop, val) {
      this.update_section_item_prop({
        sx: this.sectionIndex,
        ix: this.fieldIndex,
        prp: prop,
        val: val
      })
    },
    emitDelete() {
      this.$emit('delete', this.structures)
    },
    emitDuplicate() {
      this.$emit('duplicate', this.structures)
    }
  }
}
</script>

<style lang="scss" scoped></style>
