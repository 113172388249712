<template>
  <v-sheet
    v-bind="$attrs"
    class="pa-3 cursor-drag fullwidth d-flex flex-column"
  >
    <v-row dense align="center" justify="space-between">
      <v-col cols="12" md="11" sm="11" class="fullwidth d-flex align-center">
        <ContentEditable
          :style="`min-height: 10px; min-width: 10px;color:${structures.font_color}`"
          :class="[
            alignClass,
            `fw-${structures.font_weight}`,
            `fs-${structures.font_size}`
          ]"
          :value="structures.text"
          @input="updateProp('text', $event)"
        ></ContentEditable>
      </v-col>
      <v-col cols="12" md="1" sm="1" class="d-flex align-center justify-center">
        <v-menu
          :close-on-content-click="false"
          v-model="showMenu"
          absolute
          offset-x
        >
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-bind="attrs" v-on="on" color="primary"> mdi-cog </v-icon>
          </template>
          <v-card elevation="3" width="400">
            <v-card-title
              class="d-flex primary--text fw-700 fullwidth justify-center align-center"
            >
              Properties
            </v-card-title>
            <v-card-text>
              <div class="d-flex fullwidth mb-2 flex-column">
                <label class="subtitle-2 primary--text">Replace with</label>
                <DisplayType
                  @change="$emit('replace', $event)"
                  :value="answer_type"
                ></DisplayType>
              </div>
              <div class="d-flex fullwidth mb-2 flex-column">
                <label class="subtitle-2 primary--text">Alignment</label>
                <v-radio-group
                  :value="structures.align"
                  @change="updateProp('align', $event)"
                  class="mt-0 mb-2"
                  row
                  hide-details="auto"
                >
                  <v-radio value="left" label="Left"></v-radio>
                  <v-radio value="center" label="Center"></v-radio>
                  <v-radio value="right" label="Right"></v-radio>
                </v-radio-group>
              </div>
              <div class="d-flex fullwidth mb-2 flex-column">
                <label class="subtitle-2 primary--text">Font Weight</label>
                <v-slider
                  :value="structures.font_weight"
                  @change="updateProp('font_weight', $event)"
                  thumb-color="primary"
                  thumb-label="always"
                  min="100"
                  step="100"
                  hide-details="auto"
                  max="900"
                  class="mt-7"
                ></v-slider>
              </div>
              <div class="d-flex fullwidth mb-2 flex-column">
                <label class="subtitle-2 primary--text">Font Size</label>
                <v-slider
                  :value="structures.font_size"
                  @change="updateProp('font_size', $event)"
                  thumb-color="primary"
                  thumb-label="always"
                  min="5"
                  step="1"
                  hide-details="auto"
                  max="100"
                  class="mt-7"
                ></v-slider>
              </div>
              <div class="d-flex fullwidth mb-2 flex-column">
                <label class="subtitle-2 primary--text">Color</label>
                <v-color-picker
                  dense
                  class="mx-auto"
                  mode="hexa"
                  hide-inputs
                  hide-mode-switch
                  :value="structures.font_color"
                  @input="updateProp('font_color', $event)"
                  canvas-height="50"
                ></v-color-picker>
              </div>
            </v-card-text>
            <v-card-actions class="d-flex align-center justify-end">
              <div class="d-flex align-center justify-end">
                <v-btn
                  @click="emitDelete"
                  depressed
                  text
                  class="text-none ml-1"
                  color="primary"
                >
                  <v-icon left>mdi-delete</v-icon> Delete
                </v-btn>
                <v-btn
                  @click="emitDuplicate"
                  depressed
                  text
                  class="text-none ml-1"
                  color="primary"
                >
                  <v-icon left>mdi-content-copy</v-icon> Duplicate
                </v-btn>
              </div>
            </v-card-actions>
          </v-card>
        </v-menu>
      </v-col>
    </v-row>
  </v-sheet>
</template>

<script>
import _ from 'lodash'
import DisplayType from '@/common/Forms/DisplayType.vue'
import ContentEditable from '@/common/Forms/ContentEditable.vue'
import { mapGetters, mapMutations } from 'vuex'
export default {
  components: { DisplayType, ContentEditable },
  inheritAttrs: false,
  name: 'DisplayText',
  props: {
    sectionIndex: { type: Number, default: 0 },
    fieldIndex: { type: Number, default: 0 }
  },
  data: () => ({
    showMenu: false,
    defaults: {
      id: null,
      type: 'paragraph',
      tag: 'DisplayText',
      text: 'Some text',
      font_size: 16,
      font_weight: 300,
      font_color: '#000000',
      hover: false,
      align: 'center',
      icon: 'mdi-format-paragraph'
    },
    answer_type: 'text'
  }),
  methods: {
    ...mapMutations('forms', ['update_section_item_prop']),
    updateProp(prop, val) {
      this.update_section_item_prop({
        sx: this.sectionIndex,
        ix: this.fieldIndex,
        prp: prop,
        val: val
      })
    },
    emitDelete() {
      this.$emit('delete', this.structures)
    },
    emitDuplicate() {
      this.$emit('duplicate', this.structures)
    }
  },
  computed: {
    structures() {
      return this.$store.getters['forms/get_section_items_by_indexes'](
        this.sectionIndex,
        this.fieldIndex
      )
    },
    embed() {
      if (!this.structures) return ''
      let youtubeID = this.structures.src
        ? this.youtubeParser(this.structures.src)
        : ''
      return `https://www.youtube.com/embed/${youtubeID}`
    },
    alignClass() {
      if (this.structures && this.structures.align === 'right') return 'ml-auto'
      else if (this.structures && this.structures.align === 'center')
        return 'mx-auto'
      else return 'mr-auto'
    }
  }
}
</script>

<style></style>
