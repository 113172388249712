<template>
  <v-sheet
    v-bind="$attrs"
    class="pa-3 cursor-drag fullwidth d-flex flex-column align-center justify-start"
  >
    <label class="align-self-start mb-1">
      <ContentEditable
        :style="`color:${label_color}`"
        class="fw-500 fs-15"
        :value="structures.label"
        @input="updateProp('label', $event)"
      ></ContentEditable>
    </label>
    <v-row dense align="center" justify="space-between" class="fullwidth">
      <v-col cols="11" class="d-flex fullwidth align-center justify-center">
        <v-text-field
          :value="structures.value | format('LL')"
          clearable
          @click:clear="structures.value = null"
          hide-details="auto"
          @click="modal = true"
          append-icon="mdi-calendar"
          readonly
          outlined
          dense
        ></v-text-field>
      </v-col>
      <v-col cols="1" class="d-flex align-center justify-center">
        <v-menu
          :close-on-content-click="false"
          v-model="showMenu"
          absolute
          offset-x
        >
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-bind="attrs" v-on="on" color="primary"> mdi-cog </v-icon>
          </template>
          <v-card elevation="3" width="400">
            <v-card-title
              class="d-flex primary--text fw-700 fullwidth justify-center align-center"
            >
              Properties
            </v-card-title>
            <v-card-text>
              <div class="d-flex fullwidth mb-2 flex-column">
                <label class="subtitle-2 primary--text">Replace with</label>
                <FormType
                  :value="answer_type"
                  @change="$emit('replace', $event)"
                ></FormType>
              </div>
              <div class="d-flex fullwidth mb-2 flex-column">
                <label class="subtitle-2 primary--text">Min Date</label>
                <v-menu
                  ref="menu1"
                  v-model="menu1"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      :value="structures.min_date | format('LL')"
                      dense
                      clearable
                      @click:clear="updateProp('min_date', $event)"
                      hide-details="auto"
                      outlined
                      append-icon="mdi-calendar"
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    :value="structures.min_date"
                    @change="updateProp('min_date', $event)"
                    no-title
                    :max="structures.max_date"
                    @input="menu1 = false"
                  ></v-date-picker>
                </v-menu>
              </div>
              <div class="d-flex fullwidth mb-2 flex-column">
                <label class="subtitle-2 primary--text">Max Date</label>
                <v-menu
                  ref="menu2"
                  v-model="menu2"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      :value="structures.max_date | format('LL')"
                      dense
                      clearable
                      @click:clear="updateProp('max_date', null)"
                      hide-details="auto"
                      outlined
                      append-icon="mdi-calendar"
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    :value="structures.max_date"
                    @change="updateProp('max_date', $event)"
                    no-title
                    :min="structures.min_date"
                    @input="menu2 = false"
                  ></v-date-picker>
                </v-menu>
              </div>
            </v-card-text>
            <v-card-actions class="d-flex align-center justify-space-between">
              <div class="d-flex align-center justify-start">
                <v-checkbox
                  dense
                  :value="structures.required"
                  @change="updateProp('required', $event)"
                  class="align-self-center mt-0"
                  hide-details="auto"
                  color="primary"
                  :true-value="true"
                  :false-value="false"
                  label="Required"
                >
                </v-checkbox>
              </div>
              <div class="d-flex align-center justify-end">
                <v-btn
                  @click="emitDelete"
                  depressed
                  text
                  class="text-none ml-1"
                  color="primary"
                >
                  <v-icon left>mdi-delete</v-icon> Delete
                </v-btn>
                <v-btn
                  @click="emitDuplicate"
                  depressed
                  text
                  class="text-none ml-1"
                  color="primary"
                >
                  <v-icon left>mdi-content-copy</v-icon> Duplicate
                </v-btn>
              </div>
            </v-card-actions>
          </v-card>
        </v-menu>
      </v-col>
    </v-row>
    <v-dialog
      ref="dialog"
      v-model="modal"
      :return-value.sync="structure_value"
      persistent
      width="300px"
    >
      <v-date-picker v-model="date" scrollable>
        <v-spacer></v-spacer>
        <v-btn text color="primary" @click="modal = false"> Cancel </v-btn>
        <v-btn text color="primary" @click="$refs.dialog.save(date)">
          OK
        </v-btn>
      </v-date-picker>
    </v-dialog>
  </v-sheet>
</template>

<script>
import _ from 'lodash'
import ContentEditable from '@/common/Forms/ContentEditable.vue'
import FormType from '@/common/Forms/FormType.vue'
import { mapGetters, mapMutations } from 'vuex'
export default {
  components: { ContentEditable, FormType },
  inheritAttrs: false,
  name: 'FormDate',
  props: {
    sectionIndex: { type: Number, default: 0 },
    fieldIndex: { type: Number, default: 0 }
  },
  computed: {
    ...mapGetters('forms', ['label_color']),
    structures() {
      return this.$store.getters['forms/get_section_items_by_indexes'](
        this.sectionIndex,
        this.fieldIndex
      )
    },
    structure_value: {
      get() {
        return this.structures.value || null
      },
      set(val) {
        this.updateProp('value', val)
      }
    }
  },
  data: () => ({
    showMenu: false,
    modal: false,
    menu1: false,
    menu2: false,
    date: null,
    defaults: {
      id: null,
      type: 'date',
      tag: 'FormDate',
      label: 'Date',
      icon: 'mdi-calendar',
      show_icon: false,
      placeholder: 'Select date',
      value: null,
      dummy_value: null,
      min_date: null,
      max_date: null,
      date_format: 'Y-M-D',
      required: false,
      hover: false
    },
    answer_type: 'date'
  }),
  methods: {
    ...mapMutations('forms', ['update_section_item_prop']),
    updateProp(prop, val) {
      this.update_section_item_prop({
        sx: this.sectionIndex,
        ix: this.fieldIndex,
        prp: prop,
        val: val
      })
    },
    emitDelete() {
      this.$emit('delete', this.structures)
    },
    emitDuplicate() {
      this.$emit('duplicate', this.structures)
    }
  }
}
</script>

<style lang="scss" scoped></style>
