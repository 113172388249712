var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.section)?_c('v-card',_vm._b({staticClass:"rounded-tl-10 form-section rounded-tr-10 mb-5",class:_vm.section.id,attrs:{"flat":"","outlined":""}},'v-card',_vm.$attrs,false),[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showOptions),expression:"showOptions"}],staticClass:"section-action"},[_c('FormSectionSettingMenu',{attrs:{"value":_vm.value,"sections":_vm.exceptSelf}}),_c('HelpToolTip',{attrs:{"text":"Duplicate Section","icon":"mdi-content-duplicate","icon-class":"ma-1"},on:{"click:icon":_vm.emitDuplicate}}),_c('HelpToolTip',{attrs:{"text":"Delete Section","icon":"mdi-delete-circle-outline","icon-class":"ma-1"},on:{"click:icon":function($event){return _vm.$emit('delete')}}})],1),_c('v-card-title',{staticClass:"py-1 rounded-tl-8 rounded-tr-8",style:(("background-color:" + _vm.theme_color + ";"))}),_c('v-card-subtitle',{staticClass:"d-flex align-center justify-center my-0 cursor-drag"},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-drag-horizontal")])],1),_c('v-card-text',[(_vm.section && _vm.section.value.length)?_c('draggable',{staticClass:"d-block row form-drag",attrs:{"scroll-sensitivity":"600","force-fallback":"true"}},[_vm._l((_vm.section.value),function(form,index){return [_c(form.tag,{key:index,tag:"component",attrs:{"section-index":_vm.value,"field-index":index,"value":_vm.section.value[index]},on:{"input":function($event){return _vm.updateItemWith($event, index)},"delete":function($event){return _vm.deleteItemWith(index)},"replace":function($event){return _vm.replaceItemWith($event, index)},"duplicate":function($event){return _vm.duplicateItemWith($event, index)}}})]})],2):_vm._e()],1),_c('v-card-actions',[_c('div',{staticClass:"d-flex fullwidth align-center justify-center"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"rounded-tl-5 rounded-bl-5",attrs:{"depressed":"","color":"primary","fab":"","small":"","tile":""},on:{"click":_vm.addForm}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-plus-circle-outline")])],1)]}}],null,false,1750579962)},[_c('span',[_vm._v("New field")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"depressed":"","color":"primary","fab":"","small":"","tile":""},on:{"click":_vm.addImage}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-image")])],1)]}}],null,false,2106227506)},[_c('span',[_vm._v("Insert image")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"depressed":"","color":"primary","fab":"","small":"","tile":""},on:{"click":_vm.addVideo}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-video")])],1)]}}],null,false,2292275570)},[_c('span',[_vm._v("Insert video")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"rounded-tr-5 rounded-br-5",attrs:{"depressed":"","color":"primary","fab":"","small":"","tile":""},on:{"click":_vm.addText}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-alpha-t-box")])],1)]}}],null,false,3244307116)},[_c('span',[_vm._v("Insert text")])])],1)])],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }