<template>
  <v-sheet
    v-bind="$attrs"
    class="pa-3 cursor-drag fullwidth d-flex flex-column align-center justify-start"
  >
    <label class="align-self-start mb-1">
      <ContentEditable
        :style="`color:${label_color}`"
        class="fw-500 fs-15"
        :value="structures.label"
        @input="updateProp('label', $event)"
      ></ContentEditable>
    </label>
    <v-row dense align="center" justify="space-between" class="fullwidth">
      <v-col cols="11" class="d-flex fullwidth align-center justify-center">
        <v-file-input
          hide-details="auto"
          outlined
          dense
          chips
          :multiple="structures.multiple"
          :key="acceptedTypes"
          :accept="acceptedTypes"
          prepend-icon=""
          prepend-inner-icon="mdi-cloud-upload"
          :placeholder="structures.placeholder"
        ></v-file-input>
      </v-col>
      <v-col cols="1" class="d-flex align-center justify-center">
        <v-menu
          :close-on-content-click="false"
          v-model="showMenu"
          absolute
          offset-x
        >
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-bind="attrs" v-on="on" color="primary"> mdi-cog </v-icon>
          </template>
          <v-card elevation="3" width="400">
            <v-card-title
              class="d-flex primary--text fw-700 fullwidth justify-center align-center"
            >
              Properties
            </v-card-title>
            <v-card-text>
              <div class="d-flex fullwidth mb-1 flex-column">
                <label class="subtitle-2 primary--text">Replace with</label>
                <FormType
                  :value="answer_type"
                  @change="$emit('replace', $event)"
                ></FormType>
              </div>
              <div class="d-flex fullwidth mb-2 flex-column">
                <v-checkbox
                  hide-details="auto"
                  label="Allow only specific file types"
                  v-model="show_specific_filetypes"
                  :true-value="true"
                  :false-value="false"
                ></v-checkbox>
              </div>
              <div
                class="d-flex fullwidth mb-2 flex-start flex-wrap"
                v-if="show_specific_filetypes"
              >
                <v-checkbox
                  v-for="type in filetypes"
                  :key="type.text"
                  class="fullwidth mt-1"
                  :label="type.text"
                  :value="type.value"
                  hide-details="auto"
                  v-model="selected_filetypes"
                  :true-value="true"
                  :false-value="false"
                ></v-checkbox>
              </div>
              <div class="d-flex fullwidth mb-2 flex-column">
                <label class="subtitle-2 primary--text">
                  Maximum file size is {{ settings.fileSizeMaxUploadInMB }}MB
                </label>
              </div>
            </v-card-text>
            <v-card-actions class="d-flex align-center justify-space-between">
              <div class="d-flex align-center justify-start">
                <v-checkbox
                  dense
                  :value="structures.required"
                  @change="updateProp('required', $event)"
                  class="align-self-center mt-0"
                  hide-details="auto"
                  color="primary"
                  :true-value="true"
                  :false-value="false"
                  label="Required"
                >
                </v-checkbox>
              </div>
              <div class="d-flex align-center justify-end">
                <v-btn
                  @click="emitDelete"
                  depressed
                  text
                  class="text-none ml-1"
                  color="primary"
                >
                  <v-icon left>mdi-delete</v-icon> Delete
                </v-btn>
                <v-btn
                  @click="emitDuplicate"
                  depressed
                  text
                  class="text-none ml-1"
                  color="primary"
                >
                  <v-icon left>mdi-content-copy</v-icon> Duplicate
                </v-btn>
              </div>
            </v-card-actions>
          </v-card>
        </v-menu>
      </v-col>
    </v-row>
  </v-sheet>
</template>

<script>
import _ from 'lodash'
import ContentEditable from '@/common/Forms/ContentEditable.vue'
import FormType from '@/common/Forms/FormType.vue'
import { mapGetters, mapMutations } from 'vuex'
export default {
  components: { ContentEditable, FormType },
  inheritAttrs: false,
  name: 'FormFileUpload',
  props: {
    sectionIndex: { type: Number, default: 0 },
    fieldIndex: { type: Number, default: 0 }
  },
  data: () => ({
    showMenu: false,
    selected_filetypes: [],
    defaults: {
      id: null,
      type: 'file_upload',
      tag: 'FormFileUpload',
      tag_type: 'file',
      label: 'Upload file',
      icon: 'mdi-paperclip',
      show_icon: false,
      placeholder: 'Upload file',
      value: null,
      accept: ['*'],
      dummy_value: null,
      props: [],
      required: false,
      hover: false,
      error: false,
      multiple: false,
      max: 1
    },
    answer_type: 'file_upload',
    filetypes: [
      {
        selected: false,
        text: 'Document (Spreadsheet,Presentation,PDF, etc)',
        value: 'document',
        supported: [
          '.txt',
          '.doc',
          '.docx',
          '.xls',
          '.xlsx',
          '.pdf',
          'application/msword',
          '.ppt',
          '.pptx',
          'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
        ]
      },
      {
        selected: false,
        text: 'Image Files',
        value: 'image',
        supported: ['image/*']
      },
      {
        selected: false,
        text: 'Audio and Video Files',
        value: 'video',
        supported: ['video/*', 'audio/*']
      },
      {
        selected: false,
        text: 'Compressed Files',
        value: 'compressed',
        supported: ['.zip', '.rar']
      }
    ]
  }),
  watch: {
    selected_filetypes: {
      handler: function (val) {
        this.updateProp('accept', val.length ? val : ['*'])
      },
      deep: true,
      immediate: true
    }
  },
  computed: {
    ...mapGetters('forms', ['label_color']),
    structures() {
      return this.$store.getters['forms/get_section_items_by_indexes'](
        this.sectionIndex,
        this.fieldIndex
      )
    },
    show_specific_filetypes: {
      get() {
        return this.structures.accept &&
          this.structures.accept.length &&
          this.structures.accept.includes('*')
          ? false
          : true
      },
      set(val) {
        if (!val) this.selected_filetypes = []
        this.updateProp('accept', val ? [] : ['*'])
      }
    },
    acceptedTypes() {
      let accepted =
        this.structures.accept && this.structures.accept.length
          ? this.structures.accept
          : ['*']
      let map = this.filetypes
        .filter((i) => accepted.includes(i.value))
        .map((i) => i.supported)
      return [].concat.apply([], map).join(',')
    }
  },
  methods: {
    ...mapMutations('forms', ['update_section_item_prop']),
    updateProp(prop, val) {
      this.update_section_item_prop({
        sx: this.sectionIndex,
        ix: this.fieldIndex,
        prp: prop,
        val: val
      })
    },
    emitDelete() {
      this.$emit('delete', this.structures)
    },
    emitDuplicate() {
      this.$emit('duplicate', this.structures)
    }
  }
}
</script>

<style lang="scss" scoped></style>
