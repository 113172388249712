<template>
  <v-sheet
    v-bind="$attrs"
    class="pa-3 cursor-drag fullwidth d-flex flex-column align-center justify-start"
  >
    <label class="align-self-start mb-1">
      <ContentEditable
        :style="`color:${label_color}`"
        class="fw-500 fs-15"
        :value="structures.label"
        @input="updateProp('label', $event)"
      ></ContentEditable>
    </label>
    <v-row dense align="center" justify="space-between" class="py-2 fullwidth">
      <v-col
        v-if="structures.multiple"
        cols="11"
        class="d-flex fullwidth align-center flex-wrap justify-start"
        :class="{ 'flex-column': structures.direction !== 'row' }"
      >
        <div
          class="d-flex align-center justify-start"
          :class="{ fullwidth: structures.direction !== 'row' }"
          v-for="(item, index) in structures.items"
          :key="index"
        >
          <v-checkbox
            hide-details="auto"
            class="ma-1"
            :key="`${index}-${item}`"
          >
          </v-checkbox>
          <ContentEditable
            :style="`color:${label_color}`"
            class="fs-16 fw-500"
            :value="structures.items[index]"
            @input="updatePropItem(index, $event)"
          ></ContentEditable>
          <v-icon color="primary" @click="removePropItem(index)" right>
            mdi-close
          </v-icon>
        </div>
        <v-btn
          text
          @click="addPropItem"
          class="border-dotted border-1 mr-auto border-color--primary ma-2"
          outlined
        >
          <v-icon color="primary">mdi-plus</v-icon>
        </v-btn>
      </v-col>
      <v-col
        v-else
        cols="11"
        class="d-flex fullwidth flex-wrap overflow-hidden align-center justify-start"
      >
        <v-radio-group
          :value="structures.value"
          :row="structures.direction === 'row'"
        >
          <div
            class="d-flex flex-nowrap align-center mr-1 justify-start"
            :key="index"
            v-for="(item, index) in structures.items"
          >
            <v-radio
              class="ma-1"
              :mandatory="structures.required"
              :value="item"
            ></v-radio>
            <ContentEditable
              class="primary--text fs-16 fw-500"
              :value="structures.items[index]"
              @input="updatePropItem(index, $event)"
            ></ContentEditable>
            <v-icon color="primary" @click="removePropItem(index)" right>
              mdi-close
            </v-icon>
          </div>
          <v-btn
            text
            @click="addPropItem"
            class="border-dotted border-1 border-color--primary ma-2"
            outlined
          >
            <v-icon color="primary">mdi-plus</v-icon>
          </v-btn>
        </v-radio-group>
      </v-col>
      <v-col cols="1" class="d-flex align-center justify-center">
        <v-menu
          :close-on-content-click="false"
          v-model="showMenu"
          absolute
          offset-x
        >
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-bind="attrs" v-on="on" color="primary"> mdi-cog </v-icon>
          </template>
          <v-card elevation="3" width="400">
            <v-card-title
              class="d-flex primary--text fw-700 fullwidth justify-center align-center"
            >
              Properties
            </v-card-title>
            <v-card-text>
              <div class="d-flex fullwidth mb-2 flex-column">
                <label class="subtitle-2 primary--text">Replace with</label>
                <FormType
                  :value="answer_type"
                  @change="$emit('replace', $event)"
                ></FormType>
              </div>
              <div class="d-flex fullwidth mb-2 flex-column">
                <label class="subtitle-2 primary--text">Display Type </label>
                <v-radio-group
                  class="mt-0"
                  row
                  hide-details="auto"
                  :value="structures.direction"
                  @change="updateProp('direction', $event)"
                >
                  <v-radio value="row" label="Row"></v-radio>
                  <v-radio value="column" label="Column"></v-radio>
                </v-radio-group>
              </div>
              <div class="d-flex fullwidth mb-3 flex-column">
                <v-checkbox
                  hide-details="auto"
                  label="Allow multiple selection?"
                  :value="structures.multiple"
                  :true-value="true"
                  :false-value="false"
                  @change="handleMultipleChange('multiple', $event)"
                ></v-checkbox>
              </div>
            </v-card-text>
            <v-card-actions class="d-flex align-center justify-space-between">
              <div class="d-flex align-center justify-start">
                <v-checkbox
                  :value="structures.required"
                  @change="updateProp('required', $event)"
                  class="align-self-center mt-0 primary--text"
                  hide-details="auto"
                  color="primary"
                  :true-value="true"
                  :false-value="false"
                  label="Required"
                >
                </v-checkbox>
              </div>
              <div class="d-flex align-center justify-end">
                <v-btn
                  @click="emitDelete"
                  depressed
                  text
                  class="text-none ml-1"
                  color="primary"
                >
                  <v-icon left>mdi-delete</v-icon> Delete
                </v-btn>
                <v-btn
                  @click="emitDuplicate"
                  depressed
                  text
                  class="text-none ml-1"
                  color="primary"
                >
                  <v-icon left>mdi-content-copy</v-icon> Duplicate
                </v-btn>
              </div>
            </v-card-actions>
          </v-card>
        </v-menu>
      </v-col>
    </v-row>
  </v-sheet>
</template>

<script>
import _ from 'lodash'
import ContentEditable from '@/common/Forms/ContentEditable.vue'
import FormType from '@/common/Forms/FormType.vue'
import { mapGetters, mapMutations } from 'vuex'
export default {
  components: { ContentEditable, FormType },
  inheritAttrs: false,
  name: 'FormMultipleChoice',
  props: {
    sectionIndex: { type: Number, default: 0 },
    fieldIndex: { type: Number, default: 0 }
  },
  data: () => ({
    showMenu: false,
    defaults: {
      id: null,
      type: 'checkboxes',
      tag: 'FormMultipleChoice',
      label: 'Select',
      icon: 'mdi-checkbox-multiple-marked-outline',
      show_icon: false,
      multiple: true,
      value: [],
      dummy_value: [],
      required: false,
      hover: false,
      direction: 'column', //or row
      items: ['Option 1', 'Option 2']
    },
    answer_type: 'multiple_choice'
  }),
  computed: {
    ...mapGetters('forms', ['label_color']),
    structures() {
      return this.$store.getters['forms/get_section_items_by_indexes'](
        this.sectionIndex,
        this.fieldIndex
      )
    }
  },
  methods: {
    ...mapMutations('forms', [
      'update_section_item_prop',
      'update_section_item_prop_item',
      'delete_section_item_prop_item',
      'add_section_item_prop_item'
    ]),
    updateProp(prop, val) {
      this.update_section_item_prop({
        sx: this.sectionIndex,
        ix: this.fieldIndex,
        prp: prop,
        val: val
      })
    },
    updatePropItem(index, new_val) {
      this.update_section_item_prop_item({
        sectionIndex: this.sectionIndex,
        fieldIndex: this.fieldIndex,
        itemIndex: index,
        item: new_val
      })
    },
    emitDelete() {
      this.$emit('delete', this.structures)
    },
    emitDuplicate() {
      this.$emit('duplicate', this.structures)
    },
    addPropItem() {
      this.add_section_item_prop_item({
        sectionIndex: this.sectionIndex,
        fieldIndex: this.fieldIndex,
        item: 'New option'
      })
    },
    removePropItem(index) {
      this.delete_section_item_prop_item({
        sectionIndex: this.sectionIndex,
        fieldIndex: this.fieldIndex,
        itemIndex: index
      })
    },
    handleMultipleChange(prop, val) {
      this.updateProp(prop, val)
      this.updateProp('value', val ? [] : null)
    }
  }
}
</script>

<style lang="scss" scoped></style>
