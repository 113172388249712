<template>
  <v-sheet
    v-bind="$attrs"
    class="pa-3 cursor-drag fullwidth d-flex flex-column"
  >
    <v-row dense align="center" justify="space-between">
      <v-col
        cols="12"
        md="11"
        sm="11"
        class="fullwidth d-flex align-center"
        :key="structures.src"
      >
        <iframe
          v-if="isYoutube"
          style="max-width: 100%"
          :class="alignClass"
          class="d-block"
          allowusermedia
          allowfullscreen
          :src="embed"
          :width="structures.itemwidth"
          :height="structures.itemheight"
        ></iframe>
        <LazyVideo
          v-else
          :width="structures.itemwidth"
          :height="structures.itemheight"
          :src="structures.src"
          style="max-width: 100%; max-height: 600px"
          :class="alignClass"
        ></LazyVideo>
      </v-col>
      <v-col cols="12" md="1" sm="1" class="d-flex align-center justify-center">
        <v-menu
          :close-on-content-click="false"
          v-model="showMenu"
          absolute
          offset-x
        >
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-bind="attrs" v-on="on" color="primary"> mdi-cog </v-icon>
          </template>
          <v-card elevation="3" width="400">
            <v-card-title
              class="d-flex primary--text fw-700 fullwidth justify-center align-center"
            >
              Properties
            </v-card-title>
            <v-card-text>
              <div class="d-flex fullwidth mb-2 flex-column">
                <label class="subtitle-2 primary--text">Replace with</label>
                <DisplayType
                  @change="$emit('replace', $event)"
                  :value="answer_type"
                ></DisplayType>
              </div>
              <div class="d-flex fullwidth mb-2 flex-column">
                <label class="subtitle-2 primary--text">Alignment</label>
                <v-radio-group
                  :value="structures.align"
                  @change="updateProp('align', $event)"
                  class="mt-0 mb-2"
                  row
                  hide-details="auto"
                >
                  <v-radio value="left" label="Left"></v-radio>
                  <v-radio value="center" label="Center"></v-radio>
                  <v-radio value="right" label="Right"></v-radio>
                </v-radio-group>
              </div>
              <div class="d-flex fullwidth pr-2 mb-2 flex-column">
                <label class="subtitle-2 primary--text">Video Source URL</label>
                <v-text-field
                  hide-details="auto"
                  outlined
                  type="url"
                  :value="structures.src"
                  @input="updateProp('src', $event)"
                  dense
                  placeholder="Enter video url"
                ></v-text-field>
              </div>
              <div class="d-flex align-center justify-space-between">
                <div class="d-flex fullwidth pr-2 mb-2 flex-column">
                  <label class="subtitle-2 primary--text">Width</label>
                  <v-text-field
                    hide-details="auto"
                    outlined
                    min="100"
                    max="1000"
                    :value="structures.itemwidth"
                    @input="updateProp('itemwidth', $event)"
                    dense
                    type="number"
                    placeholder="Enter video width"
                  ></v-text-field>
                </div>
                <div class="d-flex fullwidth pl-2 mb-2 flex-column">
                  <label class="subtitle-2 primary--text">Height</label>
                  <v-text-field
                    hide-details="auto"
                    outlined
                    min="100"
                    max="1000"
                    :value="structures.itemheight"
                    @input="updateProp('itemheight', $event)"
                    dense
                    type="number"
                    placeholder="Enter video height"
                  ></v-text-field>
                </div>
              </div>
            </v-card-text>
            <v-card-actions class="d-flex align-center justify-end">
              <v-btn
                @click="emitDelete"
                depressed
                text
                class="text-none ml-1"
                color="primary"
              >
                <v-icon left>mdi-delete</v-icon> Delete
              </v-btn>
              <v-btn
                @click="emitDuplicate"
                depressed
                text
                class="text-none ml-1"
                color="primary"
              >
                <v-icon left>mdi-content-copy</v-icon> Duplicate
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-menu>
      </v-col>
    </v-row>
  </v-sheet>
</template>

<script>
import _ from 'lodash'
import DisplayType from '@/common/Forms/DisplayType.vue'
import { mapGetters, mapMutations } from 'vuex'
export default {
  components: { DisplayType },
  inheritAttrs: false,
  name: 'DisplayVideo',
  props: {
    sectionIndex: { type: Number, default: 0 },
    fieldIndex: { type: Number, default: 0 }
  },
  data: () => ({
    defaults: {
      id: null,
      type: 'video',
      tag: 'DisplayVideo',
      src: '',
      itemheight: 300,
      itemwidth: 500,
      maxWidth: '100%',
      maxHeight: '100%',
      hover: false,
      icon: 'mdi-youtube',
      label: null,
      has_label: false,
      align: 'center'
    },
    showMenu: false,
    answer_type: 'video'
  }),
  methods: {
    ...mapMutations('forms', ['update_section_item_prop']),
    updateProp(prop, val) {
      this.update_section_item_prop({
        sx: this.sectionIndex,
        ix: this.fieldIndex,
        prp: prop,
        val: val
      })
    },
    emitDelete() {
      this.$emit('delete', this.structures)
    },
    emitDuplicate() {
      this.$emit('duplicate', this.structures)
    }
  },
  computed: {
    structures() {
      return this.$store.getters['forms/get_section_items_by_indexes'](
        this.sectionIndex,
        this.fieldIndex
      )
    },
    isYoutube() {
      const regex =
        /^(?:https?:\/\/)?(?:www\.)?(?:youtube\.com|youtu\.be)\/watch\?v=([^&]+)/m
      return regex.test(this.structures.src)
    },
    embed() {
      if (!this.structures) return ''
      let youtubeID = this.structures.src
        ? this.youtubeParser(this.structures.src)
        : ''
      return `https://www.youtube.com/embed/${youtubeID}`
    },
    alignClass() {
      if (this.structures && this.structures.align === 'right') return 'ml-auto'
      else if (this.structures && this.structures.align === 'center')
        return 'mx-auto'
      else return 'mr-auto'
    }
  }
}
</script>

<style></style>
