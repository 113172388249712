<template>
  <v-menu
    v-model="menu"
    :close-on-click="false"
    :close-on-content-click="false"
  >
    <template v-slot:activator="{ on: menu, attrs }">
      <v-tooltip top color="primary">
        <template v-slot:activator="{ on: tooltip }">
          <v-btn
            class="ma-1"
            v-bind="attrs"
            v-on="{ ...tooltip, ...menu }"
            icon
            small
          >
            <v-icon color="primary">mdi-eye-settings-outline</v-icon>
          </v-btn>
        </template>
        <span>Section Setting</span>
      </v-tooltip>
    </template>
    <v-card min-width="300" max-width="400">
      <v-card-text>
        <v-row dense align="center" justify="space-between">
          <label class="subtitle-2 primary--text"> Show this section if </label>
          <HelpToolTip
            text="Close"
            icon-class="ma-1"
            @click:icon="menu = false"
            icon="mdi-close"
          ></HelpToolTip>
        </v-row>
        <FormExpressionBuilder
          :fields="fields"
          :parent="null"
          v-model="conditions"
          @is-valid="valid = $event"
        ></FormExpressionBuilder>
      </v-card-text>
      <v-card-actions class="d-flex align-center justify-center">
        <v-btn
          :disabled="!valid"
          @click="saveCondition"
          color="primary"
          class="px-5 text-none"
          depressed
        >
          <v-icon small left>mdi-content-save</v-icon> Save
        </v-btn>
        <v-btn
          @click="clearCondition"
          color="primary"
          class="px-5 text-none"
          depressed
          :disabled="!conditions || onDefault"
        >
          <v-icon small left>mdi-clipboard-text-off-outline</v-icon>
          Clear
        </v-btn>
        <v-btn
          @click="menu = false"
          color="primary"
          class="px-5 text-none"
          depressed
        >
          <v-icon small left>mdi-close</v-icon>
          Close
        </v-btn>
      </v-card-actions>
      <!-- <pre>{{ conditions }}</pre> -->
    </v-card>
  </v-menu>
</template>

<script>
import _ from 'lodash'
import FormExpressionBuilder from './FormExpressionBuilder.vue'
import { mapMutations } from 'vuex'

export default {
  inheritAttrs: false,
  name: 'FormSectionSettingMenu',
  components: { FormExpressionBuilder },
  props: {
    value: { type: Number }, //section index
    sections: { type: Array }
  },
  watch: {
    sections: {
      handler: function (val) {
        if (val && val.length) {
          this.fields = _.flatten(_.cloneDeep(val).map((j) => j.value)).filter(
            (i) => i.hasOwnProperty('value')
          )
        } else this.fields = []
      },
      deep: true,
      immediate: true
    },
    value: {
      handler: function (val) {
        this.conditions = val
          ? _.cloneDeep(this.currentSection.conditions)
          : null
      },
      immediate: true
    }
  },
  computed: {
    currentSection() {
      return this.$store.getters['forms/get_section_by_index'](this.value)
    },
    onDefault() {
      return (
        JSON.stringify(this.conditions) == JSON.stringify(this.queryDefault)
      )
    }
  },
  data: () => ({
    objKey: 1,
    valid: false,
    menu: false,
    fields: [],
    conditions: null,
    queryDefault: {
      group: true,
      connector: 'and',
      children: [
        {
          variable: '',
          operator: '==',
          compared: '',
          component: 'text'
        }
      ]
    }
  }),
  methods: {
    ...mapMutations('forms', ['replace_section']),
    clearCondition() {
      let updated = _.cloneDeep(this.currentSection)
      updated.conditions = null
      this.replace_section({
        index: this.value,
        payload: updated
      })
      this.menu = false
      this.conditions = null
      this.appSnackbar('Section rules cleared')
    },
    saveCondition() {
      let updated = _.cloneDeep(this.currentSection)
      updated.conditions = this.conditions
      this.replace_section({
        index: this.value,
        payload: updated
      })
      this.menu = false
      this.appSnackbar('Section rules updated')
    }
  }
}
</script>

<style></style>
