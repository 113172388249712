<template>
  <v-card v-bind="$attrs" flat>
    <slot name="header"></slot>
    <v-card-text>
      <draggable
        class="d-block row form-drag"
        scroll-sensitivity="600"
        force-fallback="true"
        v-model="structures"
      >
        <v-sheet
          class="mb-1"
          v-for="(section, index) in get_sections"
          :key="section.id"
        >
          <FormSection
            :value="index"
            show-options
            :has-section-setting="get_sections.length > 1"
            @delete="delete_section(index)"
            @duplicate="duplicateSection"
          >
          </FormSection>
        </v-sheet>
      </draggable>
    </v-card-text>
    <v-card-actions
      ref="scrollToMe"
      class="d-flex fullwidth align-center justify-center"
    >
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            block
            v-bind="attrs"
            v-on="on"
            @click="addSection"
            class="border-2 border-dotted"
            depressed
            text
            color="primary"
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </template>
        <span>Insert section</span>
      </v-tooltip>
    </v-card-actions>
    <!-- <pre>{{ structures }}</pre> -->
  </v-card>
</template>

<script>
import { v4 as uuidv4 } from 'uuid'
import _ from 'lodash'
import { builder_mixin } from './components/builder_mixin'
import draggable from 'vuedraggable'
import FormSection from './components/FormSection.vue'
import { mapGetters, mapMutations } from 'vuex'

export default {
  components: { draggable, FormSection },
  inheritAttrs: false,
  name: 'Builder',
  mixins: [builder_mixin],
  computed: {
    ...mapGetters('forms', ['get_sections', 'structures'])
  },
  methods: {
    ...mapMutations('forms', ['add_section', 'delete_section']),
    addSection() {
      let section = _.cloneDeep(this.section)
      section.id = uuidv4()
      this.add_section(section)
    },
    duplicateSection(section) {
      this.add_section(section)
      this.scrollToElement(section.id)
    }
  }
}
</script>

<style></style>
