<template>
  <v-sheet
    v-bind="$attrs"
    class="pa-3 cursor-drag fullwidth d-flex flex-column align-center justify-start"
  >
    <label class="align-self-start mb-1">
      <ContentEditable
        :style="`color:${label_color}`"
        class="fw-500 fs-15"
        :value="structures.label"
        @input="updateProp('label', $event)"
      ></ContentEditable>
    </label>
    <v-row dense align="center" justify="space-between" class="fullwidth">
      <v-col cols="11" class="d-flex fullwidth align-center justify-center">
        <v-select
          hide-details="auto"
          outlined
          dense
          clearable
          chips
          small-chips
          :items="structures.items"
          :multiple="structures.multiple"
          :key="structures.items.join(',')"
          :placeholder="structures.placeholder"
        ></v-select>
      </v-col>
      <v-col cols="1" class="d-flex align-center justify-center">
        <v-menu
          :close-on-content-click="false"
          v-model="showMenu"
          absolute
          offset-x
        >
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-bind="attrs" v-on="on" color="primary"> mdi-cog </v-icon>
          </template>
          <v-card elevation="3" width="400">
            <v-card-title
              class="d-flex primary--text fw-700 fullwidth justify-center align-center"
            >
              Properties
            </v-card-title>
            <v-card-text>
              <div class="d-flex fullwidth mb-2 flex-column">
                <label class="subtitle-2 primary--text">Replace with</label>
                <FormType
                  :value="answer_type"
                  @change="$emit('replace', $event)"
                ></FormType>
              </div>
              <div class="d-flex fullwidth mb-2 flex-column">
                <v-checkbox
                  color="primary"
                  hide-details="auto"
                  label="Allow multiple answer"
                  :value="structures.multiple"
                  @change="handleMultipleChange('multiple', $event)"
                  :true-value="true"
                  :false-value="false"
                ></v-checkbox>
              </div>
              <div class="d-flex fullwidth mb-2 flex-column">
                <label class="subtitle-2 primary--text">Selections</label>
                <div
                  class="d-flex mb-1 fullwidth align-center justify-space-between"
                  v-for="(item, index) in structures.items"
                  :key="index"
                >
                  <v-text-field
                    dense
                    outlined
                    hide-details="auto"
                    :value="structures.items[index]"
                    @input="updatePropItem(index, $event)"
                  ></v-text-field>
                  <v-btn icon tile @click="removePropItem(index)">
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </div>
                <v-btn
                  @click="addPropItem"
                  block
                  text
                  color="primary"
                  class="border-dotted"
                >
                  <v-icon color="primary">mdi-plus</v-icon>
                </v-btn>
              </div>
              <div class="d-flex fullwidth mb-2 flex-column">
                <label class="subtitle-2 primary--text">Placeholder</label>
                <v-text-field
                  dense
                  outlined
                  hide-details="auto"
                  :value="structures.placeholder"
                  @input="updateProp('placeholder', $event)"
                ></v-text-field>
              </div>
            </v-card-text>
            <v-card-actions class="d-flex align-center justify-space-between">
              <div class="d-flex align-center justify-start">
                <v-checkbox
                  :value="structures.required"
                  @change="updateProp('required', $event)"
                  class="align-self-center mt-0"
                  hide-details="auto"
                  color="primary"
                  :true-value="true"
                  :false-value="false"
                  label="Required"
                >
                </v-checkbox>
              </div>
              <div class="d-flex align-center justify-end">
                <v-btn
                  @click="emitDelete"
                  depressed
                  text
                  class="text-none ml-1"
                  color="primary"
                >
                  <v-icon left>mdi-delete</v-icon> Delete
                </v-btn>
                <v-btn
                  @click="emitDuplicate"
                  depressed
                  text
                  class="text-none ml-1"
                  color="primary"
                >
                  <v-icon left>mdi-content-copy</v-icon> Duplicate
                </v-btn>
              </div>
            </v-card-actions>
          </v-card>
        </v-menu>
      </v-col>
    </v-row>
  </v-sheet>
</template>

<script>
import _ from 'lodash'
import ContentEditable from '@/common/Forms/ContentEditable.vue'
import FormType from '@/common/Forms/FormType.vue'
import { mapGetters, mapMutations } from 'vuex'
export default {
  components: { ContentEditable, FormType },
  inheritAttrs: false,
  name: 'FormDropDown',
  props: {
    sectionIndex: { type: Number, default: 0 },
    fieldIndex: { type: Number, default: 0 }
  },
  computed: {
    ...mapGetters('forms', ['label_color']),
    structures() {
      return this.$store.getters['forms/get_section_items_by_indexes'](
        this.sectionIndex,
        this.fieldIndex
      )
    }
  },
  data: () => ({
    showMenu: false,
    defaults: {
      id: null,
      type: 'dropdown',
      tag: 'FormDropdown',
      label: 'Select',
      icon: 'mdi-arrow-down-drop-circle-outline',
      show_icon: false,
      placeholder: 'Select your answer',
      value: null,
      dummy_value: null,
      multiple: false,
      required: false,
      hover: false,
      items: ['Select 1', 'Select 2']
    },
    answer_type: 'dropdown'
  }),
  methods: {
    ...mapMutations('forms', [
      'update_section_item_prop',
      'update_section_item_prop_item',
      'add_section_item_prop_item',
      'delete_section_item_prop_item'
    ]),
    updateProp(prop, val) {
      this.update_section_item_prop({
        sx: this.sectionIndex,
        ix: this.fieldIndex,
        prp: prop,
        val: val
      })
    },
    updatePropItem(index, new_val) {
      this.update_section_item_prop_item({
        sectionIndex: this.sectionIndex,
        fieldIndex: this.fieldIndex,
        itemIndex: index,
        item: new_val
      })
    },
    emitDelete() {
      this.$emit('delete', this.structures)
    },
    emitDuplicate() {
      this.$emit('duplicate', this.structures)
    },
    addPropItem() {
      this.add_section_item_prop_item({
        sectionIndex: this.sectionIndex,
        fieldIndex: this.fieldIndex,
        item: 'New selection'
      })
    },
    removePropItem(index) {
      this.delete_section_item_prop_item({
        sectionIndex: this.sectionIndex,
        fieldIndex: this.fieldIndex,
        itemIndex: index
      })
    },
    handleMultipleChange(prop, val) {
      this.updateProp(prop, val)
      this.updateProp('value', val ? [] : null)
    }
  }
}
</script>

<style lang="scss" scoped></style>
