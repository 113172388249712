<template>
  <v-card
    flat
    outlined
    :class="section.id"
    v-bind="$attrs"
    v-if="section"
    class="rounded-tl-10 form-section rounded-tr-10 mb-5"
  >
    <div class="section-action" v-show="showOptions">
      <FormSectionSettingMenu
        :value="value"
        :sections="exceptSelf"
      ></FormSectionSettingMenu>
      <HelpToolTip
        text="Duplicate Section"
        icon="mdi-content-duplicate"
        icon-class="ma-1"
        @click:icon="emitDuplicate"
      ></HelpToolTip>
      <HelpToolTip
        text="Delete Section"
        icon="mdi-delete-circle-outline"
        icon-class="ma-1"
        @click:icon="$emit('delete')"
      ></HelpToolTip>
    </div>
    <v-card-title
      :style="`background-color:${theme_color};`"
      class="py-1 rounded-tl-8 rounded-tr-8"
    ></v-card-title>
    <v-card-subtitle
      class="d-flex align-center justify-center my-0 cursor-drag"
    >
      <v-icon color="primary">mdi-drag-horizontal</v-icon>
    </v-card-subtitle>
    <v-card-text>
      <!-- v-model="section.value" -->
      <draggable
        v-if="section && section.value.length"
        class="d-block row form-drag"
        scroll-sensitivity="600"
        force-fallback="true"
      >
        <template v-for="(form, index) in section.value">
          <!-- components like FormShortText, etc -->
          <component
            :section-index="value"
            :field-index="index"
            :is="form.tag"
            :key="index"
            :value="section.value[index]"
            @input="updateItemWith($event, index)"
            @delete="deleteItemWith(index)"
            @replace="replaceItemWith($event, index)"
            @duplicate="duplicateItemWith($event, index)"
          ></component>
        </template>
      </draggable>
    </v-card-text>
    <v-card-actions>
      <div class="d-flex fullwidth align-center justify-center">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              @click="addForm"
              depressed
              color="primary"
              class="rounded-tl-5 rounded-bl-5"
              fab
              small
              tile
            >
              <v-icon>mdi-plus-circle-outline</v-icon>
            </v-btn>
          </template>
          <span>New field</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              @click="addImage"
              depressed
              color="primary"
              fab
              small
              tile
            >
              <v-icon>mdi-image</v-icon>
            </v-btn>
          </template>
          <span>Insert image</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              @click="addVideo"
              depressed
              color="primary"
              fab
              small
              tile
            >
              <v-icon>mdi-video</v-icon>
            </v-btn>
          </template>
          <span>Insert video</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              @click="addText"
              depressed
              color="primary"
              class="rounded-tr-5 rounded-br-5"
              fab
              small
              tile
            >
              <v-icon>mdi-alpha-t-box</v-icon>
            </v-btn>
          </template>
          <span>Insert text</span>
        </v-tooltip>
      </div>
    </v-card-actions>
  </v-card>
</template>

<script>
import _ from 'lodash'
import { v4 as uuidv4 } from 'uuid'
import { builder_mixin } from './builder_mixin'
import draggable from 'vuedraggable'
import FormShortText from './FormShortText.vue'
import ContentEditable from '@/common/Forms/ContentEditable.vue'
import FormParagraph from './FormParagraph.vue'
import DisplayVideo from './DisplayVideo.vue'
import DisplayText from './DisplayText.vue'
import DisplayImage from './DisplayImage.vue'
import FormFileUpload from './FormFileUpload.vue'
import FormDropdown from './FormDropdown.vue'
import FormCheckbox from './FormCheckbox.vue'
import FormMultipleChoice from './FormMultipleChoice.vue'
import FormDate from './FormDate.vue'
import FormTime from './FormTime.vue'
import { mapGetters, mapMutations } from 'vuex'
import ExpressionBuilder from '../../Rules/FormExpressionBuilder.vue'
import FormSectionSettingMenu from '../../Rules/FormSectionSettingMenu.vue'
import { defaultFormat } from 'moment'

export default {
  components: {
    draggable,
    ContentEditable,
    FormShortText,
    FormParagraph,
    DisplayVideo,
    DisplayText,
    DisplayImage,
    FormFileUpload,
    FormDropdown,
    FormCheckbox,
    FormMultipleChoice,
    FormDate,
    FormTime,
    ExpressionBuilder,
    FormSectionSettingMenu
  },
  inheritAttrs: false,
  mixins: [builder_mixin],
  name: 'FormSection',
  props: {
    value: Number,
    showOptions: { type: Boolean, default: false },
    hasSectionSetting: { type: Boolean, default: true }
  },
  computed: {
    ...mapGetters('forms', ['theme_color', 'get_sections']),
    exceptSelf() {
      if (!this.section) return []
      else if (!this.get_sections.length) return []
      return this.get_sections.filter((i) => i.id != this.section.id)
    }
  },
  watch: {
    value: {
      handler: function (val) {
        this.section = this.$store.getters['forms/get_section_by_index'](val)
      },
      immediate: true
    }
  },
  data: () => ({
    menu: false,
    section: null,
    defaults: {
      id: null,
      type: 'section',
      tag: 'FormSection',
      label: 'Section',
      icon: 'mdi-alpha-s-box',
      show_icon: false,
      hover: false,
      value: [],
      dummy_value: [],
      conditions: null
    },
    comparators: [
      { text: 'Equal to', value: '==' },
      { text: 'Not equal to', value: '!=' },
      { text: 'Greater than', value: '>' },
      { text: 'Less than', value: '<' },
      { text: 'Greater than or Equal to', value: '>=' },
      { text: 'Less than or Equal to', value: '<=' }
    ]
  }),
  methods: {
    ...mapMutations('forms', [
      'add_section_item',
      'replace_section_item',
      'delete_section_item',
      'replace_section',
      'duplicate_section_item'
    ]),
    replaceItemWith(new_field, index) {
      let form = _.cloneDeep(this[new_field])
      if (this[new_field]) {
        form.id = uuidv4()
        this.replace_section_item({
          sIndex: this.value,
          iIndex: index,
          payload: form
        })
      } else {
        this.appSnackbar('This form type is unavailable', 'error')
      }
    },
    updateItemWith(form, index) {
      this.replace_section_item({
        sIndex: this.value,
        iIndex: index,
        payload: form
      })
    },
    deleteItemWith(index) {
      this.delete_section_item({ sIndex: this.value, iIndex: index })
    },
    duplicateItemWith(form, index) {
      let new_form = _.cloneDeep(form)
      new_form.id = uuidv4()
      this.duplicate_section_item({
        sIndex: this.value,
        iIndex: index,
        payload: new_form
      })
    },
    emitDuplicate() {
      let structure = _.cloneDeep(this.section)
      structure.id = uuidv4()
      if (this.section.value.length) {
        structure.value = _.cloneDeep(this.section.value).map((modified) => {
          modified.id = uuidv4()
          if (modified.hasOwnProperty('src')) modified.src = null
          if (modified.hasOwnProperty('attachment_uuid'))
            modified.attachment_uuid = null
          return modified
        })
      }
      this.$emit('duplicate', structure)
    },
    addVideo() {
      let video = _.cloneDeep(this.video)
      video.id = uuidv4()
      this.add_section_item({ index: this.value, payload: video })
    },
    addImage() {
      let image = _.cloneDeep(this.image)
      image.id = uuidv4()
      this.add_section_item({ index: this.value, payload: image })
    },
    addText() {
      let text = _.cloneDeep(this.text)
      text.id = uuidv4()
      this.add_section_item({ index: this.value, payload: text })
    },
    addForm() {
      let new_form = _.cloneDeep(this.short_text)
      new_form.id = uuidv4()
      this.add_section_item({ index: this.value, payload: new_form })
    }
  }
}
</script>

<style lang="scss" scoped>
.form-section {
  .section-action {
    position: absolute;
    right: 0;
    top: 0;
    margin-top: 10px;
    margin-right: 10px;
  }
}
</style>
