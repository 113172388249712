<template>
  <v-sheet
    v-bind="$attrs"
    class="form-paragraph pa-3 cursor-drag fullwidth d-flex flex-column align-center justify-start"
  >
    <label class="align-self-start mb-1">
      <ContentEditable
        :style="`color:${label_color}`"
        class="fw-500 fs-15"
        :value="structures.label"
        @input="updateProp('label', $event)"
      ></ContentEditable>
    </label>
    <v-row dense align="center" justify="space-between" class="fullwidth">
      <v-col cols="11" class="d-flex fullwidth align-center justify-center">
        <v-textarea
          hide-details="auto"
          outlined
          dense
          :key="structures.rows"
          auto-grow
          :rows="structures.rows"
          :placeholder="structures.placeholder"
          :readonly="readOnly"
        ></v-textarea>
      </v-col>
      <v-col cols="1" class="d-flex align-center justify-center">
        <v-menu
          :close-on-content-click="false"
          v-model="showMenu"
          absolute
          offset-x
        >
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-bind="attrs" v-on="on" color="primary"> mdi-cog </v-icon>
          </template>
          <v-card elevation="3" width="400">
            <v-card-title
              class="d-flex primary--text fw-700 fullwidth justify-center align-center"
            >
              Properties
            </v-card-title>
            <v-card-text>
              <div class="d-flex fullwidth mb-2 flex-column">
                <label class="subtitle-2 primary--text">Replace with</label>
                <FormType
                  :value="answer_type"
                  @change="$emit('replace', $event)"
                ></FormType>
              </div>
              <div class="d-flex fullwidth mb-2 flex-column">
                <label class="subtitle-2 primary--text">
                  Initial number of rows
                </label>
                <v-slider
                  :value="structures.rows"
                  @change="updateProp('rows', $event)"
                  thumb-color="primary"
                  thumb-label="always"
                  min="1"
                  hide-details="auto"
                  max="10"
                  class="mt-7"
                ></v-slider>
              </div>
            </v-card-text>
            <v-card-actions class="d-flex align-center justify-space-between">
              <div class="d-flex align-center justify-start">
                <v-checkbox
                  dense
                  :value="structures.required"
                  @change="updateProp('required', $event)"
                  class="align-self-center mt-0"
                  hide-details="auto"
                  color="primary"
                  :true-value="true"
                  :false-value="false"
                  label="Required"
                >
                </v-checkbox>
              </div>
              <div class="d-flex align-center justify-end">
                <v-btn
                  @click="emitDelete"
                  depressed
                  text
                  class="text-none ml-1"
                  color="primary"
                >
                  <v-icon left>mdi-delete</v-icon> Delete
                </v-btn>
                <v-btn
                  @click="emitDuplicate"
                  depressed
                  text
                  class="text-none ml-1"
                  color="primary"
                >
                  <v-icon left>mdi-content-copy</v-icon> Duplicate
                </v-btn>
              </div>
            </v-card-actions>
          </v-card>
        </v-menu>
      </v-col>
    </v-row>
  </v-sheet>
</template>

<script>
import _ from 'lodash'
import ContentEditable from '@/common/Forms/ContentEditable.vue'
import FormType from '@/common/Forms/FormType.vue'
import { mapGetters, mapMutations } from 'vuex'
export default {
  components: { ContentEditable, FormType },
  inheritAttrs: false,
  name: 'FormParagraph',
  props: {
    sectionIndex: { type: Number, default: 0 },
    fieldIndex: { type: Number, default: 0 },
    readOnly: { type: Boolean, default: true }
  },
  data: () => ({
    showMenu: false,
    defaults: {
      id: null,
      type: 'textarea',
      tag: 'FormParagraph',
      label: 'Untitled Question',
      icon: 'mdi-format-line-weight',
      show_icon: false,
      placeholder: 'Your answer',
      value: null,
      dummy_value: null,
      rows: 3,
      required: false,
      hover: false
    },
    answer_type: 'long_text'
  }),
  computed: {
    ...mapGetters('forms', ['label_color']),
    structures() {
      return this.$store.getters['forms/get_section_items_by_indexes'](
        this.sectionIndex,
        this.fieldIndex
      )
    }
  },
  methods: {
    ...mapMutations('forms', ['update_section_item_prop']),
    updateProp(prop, val) {
      this.update_section_item_prop({
        sx: this.sectionIndex,
        ix: this.fieldIndex,
        prp: prop,
        val: val
      })
    },
    emitDelete() {
      this.$emit('delete', this.structures)
    },
    emitDuplicate() {
      this.$emit('duplicate', this.structures)
    }
  }
}
</script>

<style lang="scss" scoped></style>
