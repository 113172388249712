<template>
  <v-card v-bind="$attrs" class="rounded-5" :color="randomHex" outlined flat>
    <slot name="top"></slot>
    <v-card-title>
      <div class="d-flex justify-center align-center">
        <v-select
          dense
          outlined
          autofocus
          label="Connector"
          :rules="[requiredRule()]"
          hide-details="auto"
          item-text="text"
          item-value="value"
          :items="connectors"
          v-model="query.connector"
          class="ma-1"
        >
        </v-select>

        <v-btn
          color="primary"
          depressed
          v-if="parent != null"
          @click="$emit('remove-group', $event)"
          class="text-none ma-1"
        >
          Remove Group
        </v-btn>
      </div>
    </v-card-title>
    <v-card-text
      v-for="(item, index) in query.children"
      :key="index"
      class="card-body form-inline"
    >
      <FormExpressionBuilder
        v-if="item.group"
        :fields="fields"
        :value="item"
        :parent="query"
        @is-valid="isSubGroupValid"
        @remove-group="removeItem(item)"
      ></FormExpressionBuilder>
      <v-sheet
        v-else
        color="transparent"
        class="d-flex align-center justify-space-between"
      >
        <div class="d-flex flex-column align-center justify-start">
          <v-select
            hide-details="auto"
            dense
            label="Select Question"
            item-text="label"
            item-value="id"
            :items="availableFields"
            outlined
            no-data-text="No question found from other sections"
            placeholder="Select question to check"
            :rules="[requiredRule()]"
            v-model="item.variable"
            @change="handleChangeVariable(index, $event)"
            class="ma-1 fullwidth"
          ></v-select>
          <v-select
            outlined
            dense
            label="Check if answer to question is"
            :rules="[requiredRule()]"
            hide-details="auto"
            item-text="text"
            item-value="value"
            :items="operators"
            v-model="item.operator"
            class="ma-1 fullwidth"
          >
          </v-select>
          <v-text-field
            v-if="item.component === 'text'"
            :key="item.variable"
            :rules="[requiredRule()]"
            class="ma-1 fullwidth"
            v-model="item.compared"
            outlined
            dense
            label="If answer is"
            placeholder="Enter compare to this value"
            hide-details="auto"
          ></v-text-field>
          <v-select
            v-else
            :key="item.variable"
            :rules="[requiredRule()]"
            class="ma-1 fullwidth"
            v-model="item.compared"
            outlined
            dense
            :items="getChoices(item.variable)"
            label="If answer is"
            placeholder="Enter compare to this value"
            hide-details="auto"
          ></v-select>
        </div>
        <HelpToolTip
          text="Remove Rule"
          icon-class="ma-1"
          @click:icon="removeItem(item, query)"
          icon="mdi-delete-circle-outline"
        ></HelpToolTip>
      </v-sheet>
    </v-card-text>
    <v-card-actions class="d-flex align-center justify-center">
      <v-btn
        small
        color="secondary"
        depressed
        @click="addNode"
        class="ma-1 text-none"
      >
        <v-icon left>mdi-plus</v-icon> Add Condition
      </v-btn>
      <!--temp: remove group condition  -->
      <!-- <v-btn
        small
        v-if="parent == null"
        color="secondary"
        depressed
        @click="addGroup"
        class="text-none ma-1"
      >
        <v-icon left>mdi-plus</v-icon> Add Group Condition
      </v-btn> -->
    </v-card-actions>
    <slot name="bottom"></slot>
  </v-card>
</template>

<script>
import _ from 'lodash'
export default {
  inheritAttrs: false,
  name: 'FormExpressionBuilder',
  props: {
    value: Object,
    parent: Object,
    fields: Array
  },
  watch: {
    value: {
      handler: function (val) {
        this.query = val ? val : _.cloneDeep(this.queryDefault)
      },
      immediate: true,
      deep: true
    },
    fields: {
      handler: function (val) {
        this.availableFields = val ? _.cloneDeep(val) : []
      },
      immediate: true,
      deep: true
    },
    query: {
      handler: function (val) {
        this.$emit('input', val)
      },
      deep: true,
      immediate: true
    },
    valid: {
      handler: function (val) {
        this.$emit('is-valid', val)
      },
      immediate: true
    }
  },
  computed: {
    randomHex() {
      return `rgba(
        ${this.randBetween(0, 255)},
        ${this.randBetween(0, 255)},
        ${this.randBetween(0, 255)},
        0.2)`
    },
    valid() {
      for (let index = 0; index < this.query.children.length; index++) {
        if (!this.query.children[index].hasOwnProperty('group')) {
          if (!this.validateChild(this.query.children[index])) {
            return false
            break
          }
        }
      }
      return this.subgroup_is_valid
    }
  },
  data: () => ({
    query: null,
    subgroup_is_valid: true,
    availableFields: [],
    connectors: [
      { text: 'And', value: 'and' },
      { text: 'Or', value: 'or' }
    ],
    operators: [
      { value: '==', text: 'Equals to' },
      { value: '!=', text: 'Not Equals to' },
      { value: '>', text: 'Greater than' },
      { value: '>=', text: 'Greater or Equals to' },
      { value: '<', text: 'Less than' },
      { value: '<=', text: 'Less or Equals to' }
    ],
    queryDefault: {
      group: true,
      children: [
        {
          compared: '',
          operator: '==',
          variable: '',
          component: 'text'
        }
      ],
      connector: 'and'
    }
  }),
  methods: {
    isSubGroupValid(isvalid) {
      this.subgroup_is_valid = isvalid
    },
    validateChild(child) {
      return child &&
        child.variable &&
        child.variable != '' &&
        child.operator &&
        child.operator != '' &&
        child.compared &&
        child.compared != '' &&
        child.component &&
        child.component != ''
        ? true
        : false
    },
    randBetween(min, max) {
      return min + Math.floor(Math.random() * (max - min + 1))
    },
    addNode() {
      this.query.children.push({
        compared: '',
        operator: '==',
        variable: '',
        component: 'text'
      })
    },
    addGroup() {
      this.query.children.push({
        group: true,
        children: [
          {
            compared: '',
            operator: '==',
            variable: '',
            component: 'text'
          }
        ],
        connector: 'and'
      })
    },
    removeItem(item) {
      let index = this.query.children.indexOf(item)
      this.query.children.splice(index, 1)
    },
    getChoices(id) {
      let index = this.availableFields.findIndex((i) => i.id === id)
      if (~index) {
        return this.availableFields[index].items || []
      } else return []
    },
    handleChangeVariable(index, new_val) {
      let orig = this.query.children[index]
      let choices = this.getChoices(new_val)
      orig.component = choices.length > 0 ? 'select' : 'text'
      this.query.children.splice(index, 1, orig)
    }
  }
}
</script>

<style scoped></style>
