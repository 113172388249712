<template>
  <v-select
    v-bind="$attrs"
    v-model="type"
    dense hide-details="auto"
    item-value="value"
    item-text="text"
    outlined
    @change="emitChange"
    :items="type_of_answers_filtered"
  >
    <template v-slot:item="{ item }">
      <span>
        <v-list-item :disabled="item.disabled">
          <v-list-item-icon>
            <v-icon :color="item.disabled ? 'grey' : 'primary'">
              {{ item.icon }}
            </v-icon>
          </v-list-item-icon>
          <v-list-item-title
            :class="[item.disabled ? 'grey--text' : 'primary--text']"
            class="fw-700"
          >
            {{ item.text }}
          </v-list-item-title>
        </v-list-item>
      </span>
    </template>
  </v-select>
</template>

<script>
export default {
  inheritAttrs: false,
  name: "DisplayType",
  props: {
    value: { type: String, default: "text" },
  },
  data: () => ({
    type: "text",
    text: {
      id: null,
      type: "text",
      tag: "div",
      description: "Text",
      text: "Some text",
      hover: false,
      icon: "mdi-format-letter-case",
      align: "left",
      fontSize: 16,
    },
    type_of_answers: [
      {
        icon: "mdi-format-letter-case",
        text: "Text",
        value: "text",
        disabled: false,
      },
      { icon: "mdi-video", text: "Video", value: "video", disabled: false },
      { icon: "mdi-image", text: "Image", value: "image", disabled: false },
    ],
  }),
  computed: {
    type_of_answers_filtered() {
      return this.type_of_answers.map((i) => {
        i.disabled = i.value === this.value;
        return i;
      });
    },
  },
  watch: {
    value: {
      handler: function (val) {
        this.type = val;
      },
      immediate: true,
    },
    type: {
      handler: function (val) {
        this.$emit("input", val);
      },
      immediate: true,
    },
  },
  methods: {
    emitChange() {
      this.$emit("change", this.type);
    },
  },
};
</script>

<style>
</style>