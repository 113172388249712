<template>
  <v-sheet
    v-bind="$attrs"
    class="form-short-text pa-3 cursor-drag fullwidth d-flex flex-column align-center justify-start"
  >
    <label class="align-self-start mb-1">
      <ContentEditable
        :style="`color:${label_color}`"
        class="fw-500 fs-15"
        :value="structures.label"
        @input="updateProp('label', $event)"
      ></ContentEditable>
    </label>
    <v-row dense align="center" justify="space-between" class="fullwidth">
      <v-col cols="11" class="d-flex fullwidth align-center justify-center">
        <v-text-field
          hide-details="auto"
          outlined
          dense
          :type="structures.type"
          :placeholder="structures.placeholder"
          :readonly="readOnly"
        ></v-text-field>
      </v-col>
      <v-col cols="1" class="d-flex align-center justify-center">
        <v-menu
          :close-on-content-click="false"
          v-model="showMenu"
          absolute
          offset-x
        >
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-bind="attrs" v-on="on" color="primary"> mdi-cog </v-icon>
          </template>
          <v-card elevation="3" width="400">
            <v-card-title
              class="d-flex primary--text fw-700 fullwidth justify-center align-center"
            >
              Properties
            </v-card-title>
            <v-card-text>
              <div class="d-flex fullwidth mb-2 flex-column">
                <label class="subtitle-2 primary--text">Replace with</label>
                <FormType
                  @change="$emit('replace', $event)"
                  :value="answer_type"
                ></FormType>
              </div>
              <div class="d-flex fullwidth mb-2 flex-column">
                <label class="subtitle-2 primary--text">Field Type</label>
                <v-radio-group
                  :value="structures.type"
                  @change="updateProp('type', $event)"
                  class="mt-0 mb-2"
                  row
                  hide-details="auto"
                >
                  <v-radio value="text" label="Text"></v-radio>
                  <v-radio value="number" label="Number"></v-radio>
                  <v-radio value="email" label="Email"></v-radio>
                  <v-radio value="url" label="URL"></v-radio>
                </v-radio-group>
              </div>
              <div
                class="d-flex align-center justify-space-between"
                v-if="structures.type === 'number'"
              >
                <div class="d-flex fullwidth pr-2 mb-2 flex-column">
                  <label class="subtitle-2 primary--text">Minimum Value</label>
                  <v-text-field
                    hide-details="auto"
                    outlined
                    :value="structures.min"
                    @input="updateProp('min', $event)"
                    :max="structures.max"
                    dense
                    type="number"
                    placeholder="Enter minimum value"
                  ></v-text-field>
                </div>
                <div class="d-flex fullwidth pl-2 mb-2 flex-column">
                  <label class="subtitle-2 primary--text">Maximum Value</label>
                  <v-text-field
                    hide-details="auto"
                    outlined
                    :min="structures.min"
                    :value="structures.max"
                    @input="updateProp('max', $event)"
                    dense
                    type="number"
                    placeholder="Enter maximum value"
                  ></v-text-field>
                </div>
              </div>
              <div class="d-flex fullwidth mb-2 flex-column">
                <label class="subtitle-2 primary--text">Placeholder</label>
                <v-text-field
                  hide-details="auto"
                  outlined
                  :value="structures.placeholder"
                  @input="updateProp('placeholder', $event)"
                  dense
                  placeholder="Enter placeholder"
                ></v-text-field>
              </div>
            </v-card-text>
            <v-card-actions class="d-flex align-center justify-space-between">
              <div class="d-flex align-center justify-start">
                <v-checkbox
                  dense
                  :value="structures.required"
                  @change="updateProp('required', $event)"
                  class="align-self-center mt-0"
                  hide-details="auto"
                  color="primary"
                  :true-value="true"
                  :false-value="false"
                  label="Required"
                >
                </v-checkbox>
              </div>
              <div class="d-flex align-center justify-end">
                <v-btn
                  @click="emitDelete"
                  depressed
                  text
                  class="text-none ml-1"
                  color="primary"
                >
                  <v-icon left>mdi-delete</v-icon> Delete
                </v-btn>
                <v-btn
                  @click="emitDuplicate"
                  depressed
                  text
                  class="text-none ml-1"
                  color="primary"
                >
                  <v-icon left>mdi-content-copy</v-icon> Duplicate
                </v-btn>
              </div>
            </v-card-actions>
          </v-card>
        </v-menu>
      </v-col>
    </v-row>
  </v-sheet>
</template>

<script>
import _ from 'lodash'
import ContentEditable from '@/common/Forms/ContentEditable.vue'
import FormType from '@/common/Forms/FormType.vue'
import { mapGetters, mapMutations } from 'vuex'
export default {
  components: { ContentEditable, FormType },
  inheritAttrs: false,
  name: 'FormShortText',
  props: {
    sectionIndex: { type: Number, default: 0 },
    fieldIndex: { type: Number, default: 0 },
    readOnly: { type: Boolean, default: true }
  },
  data: () => ({
    showMenu: false,
    defaults: {
      id: null,
      type: 'text',
      tag: 'FormShortText',
      label: 'Untitled Question',
      icon: 'mdi-alpha-t-box-outline',
      show_icon: false,
      placeholder: 'Your answer',
      value: null,
      dummy_value: null,
      required: true,
      hover: false,
      min: null,
      max: null
    },
    answer_type: 'short_text'
  }),
  computed: {
    ...mapGetters('forms', ['label_color']),
    structures() {
      return this.$store.getters['forms/get_section_items_by_indexes'](
        this.sectionIndex,
        this.fieldIndex
      )
    }
  },
  methods: {
    ...mapMutations('forms', ['update_section_item_prop']),
    updateProp(prop, val) {
      this.update_section_item_prop({
        sx: this.sectionIndex,
        ix: this.fieldIndex,
        prp: prop,
        val: val
      })
    },
    emitDelete() {
      this.$emit('delete', this.structures)
    },
    emitDuplicate() {
      this.$emit('duplicate', this.structures)
    }
  }
}
</script>

<style lang="scss" scoped></style>
